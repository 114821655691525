import { ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import {
  background,
  BackgroundProps,
  border,
  BorderProps,
  flexbox,
  FlexboxProps,
  gridArea,
  GridAreaProps,
  gridColumn,
  GridColumnProps,
  gridTemplateAreas,
  GridTemplateAreasProps,
  gridTemplateColumns,
  GridTemplateColumnsProps,
  gridTemplateRows,
  GridTemplateRowsProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  ResponsiveValue,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  Theme,
  TLengthStyledSystem,
  typography,
  TypographyProps,
  system,
} from 'styled-system';

import {
  buttonPrimaryStyles,
  buttonSecondaryStyles,
  buttonStrokeStyles,
  buttonCustomIconTextStyle,
  buttonStrokeTransparentStyles,
  buttonGostStyles,
} from 'modules/Theme/mixins/buttonImports';
import styled from 'modules/Theme/styled-components';
import Box from 'modules/Ui/Box';

import { validProps } from '../../Html/A';
import { isValidProp } from '../../Html/cleanProps';
import { commonProps } from '../../Html/HtmlComponent';
import hoverStyle from '../hoverStyle';

export interface InternalLinkProps
  extends PositionProps,
    BackgroundProps,
    BorderProps,
    FlexboxProps,
    GridAreaProps,
    GridColumnProps,
    GridTemplateAreasProps,
    GridTemplateColumnsProps,
    GridTemplateRowsProps,
    LayoutProps,
    ShadowProps,
    TypographyProps,
    SpaceProps {
  ariaLabel?: string;
  children?: string | ReactNode;
  columnGap?: {} | string;
  hoverUnderline?: boolean;
  icon?: string | ReactNode;
  iconColor?: string;
  iconMarginRight?: string | number;
  iconSize?: number;
  rowGap?: {} | string;
  variant?:
    | 'primary'
    | 'secondary'
    | 'stroke'
    | 'strokeTransparent'
    | 'link'
    | 'ghost'
    | 'ghostDark'
    | 'light';
  to: LinkProps['to'];
  color?:
    | string &
        ResponsiveValue<
          string | number | symbol,
          Required<Theme<TLengthStyledSystem>>
        >;
  textDecoration?: 'underline' | 'none';
}

interface StyledInternalLinkProps {
  variant?:
    | 'primary'
    | 'secondary'
    | 'stroke'
    | 'strokeTransparent'
    | 'link'
    | 'ghost'
    | 'ghostDark'
    | 'light';
  icon?: string | ReactNode;
}

const StyledLink = styled(Link).withConfig({
  shouldForwardProp: (prop) => {
    return isValidProp([...commonProps, ...validProps, 'to'], prop);
  },
})<InternalLinkProps & StyledInternalLinkProps>`
  ${({ icon }) => icon && buttonCustomIconTextStyle}
  ${({ variant }) => variant === 'primary' && buttonPrimaryStyles}
  ${({ variant }) => variant === 'secondary' && buttonSecondaryStyles}
  ${({ variant }) => variant === 'stroke' && buttonStrokeStyles}
  ${({ variant }) => variant === 'ghost' && buttonGostStyles}
  ${({ variant }) =>
    variant === 'strokeTransparent' && buttonStrokeTransparentStyles}
  ${({ hoverUnderline }) => hoverUnderline && hoverStyle}
  ${({ color }) => color && `color: ${color};`}
  ${({ textDecoration }) =>
    textDecoration && `text-decoration: ${textDecoration};`}
  ${background}
  ${border}
  ${flexbox}
  ${gridArea}
  ${gridColumn}
  ${gridTemplateAreas}
  ${gridTemplateColumns}
  ${gridTemplateRows}
  ${layout}
  ${position}
  ${shadow}
  ${space}
  ${typography}
  ${system({
    gap: {
      property: 'gap',
      transform: (value) => `${value}`,
    },
    rowGap: {
      property: 'rowGap',
      transform: (value) => `${value}`,
    },
    columnGap: {
      property: 'columnGap',
      transform: (value) => `${value}`,
    },
  })}
`;

const InternalLink = (props: InternalLinkProps) => {
  const {
    ariaLabel,
    children,
    hoverUnderline,
    icon,
    iconColor,
    iconSize,
    variant,
    iconMarginRight,
    textDecoration,
    ...rest
  } = props;

  const linkDisplay = () => {
    if (variant) {
      if (icon) {
        return 'grid';
      }
      return 'inline-block';
    }
    return undefined;
  };

  return (
    <StyledLink
      aria-label={ariaLabel}
      display={linkDisplay()}
      gridTemplateColumns={icon ? '24px 1fr' : undefined}
      columnGap={icon ? '12px' : undefined}
      hoverUnderline={hoverUnderline}
      icon={icon}
      textDecoration={textDecoration}
      variant={variant}
      {...rest}
    >
      {icon && <>{icon}</>}
      {variant ? (
        <Box
          color="inherit"
          fontSize="inherit"
          position="relative"
          tag="span"
          whiteSpace="nowrap"
        >
          {children}
        </Box>
      ) : (
        <>{children}</>
      )}
    </StyledLink>
  );
};

export default InternalLink;
