import { FC, ReactNode } from 'react';
import { Hidden, Visible } from 'react-grid-system';

import { menuSizes } from 'modules/Ui/Menu/MenuMobile/menuSizes';

import Box from '../Box';
import ButtonBack from '../ButtonBack';

interface Props {
  action?: ReactNode;
  actionVisibleInMobile?: boolean;
  children: ReactNode;
  id: string;
  goBack?: () => void;
  title?: string;
}

export const PageListLayout: FC<Props> = ({
  action,
  actionVisibleInMobile = true,
  children,
  id,
  goBack,
  title,
}) => {
  return (
    <>
      <Visible xs>
        <Box
          backgroundColor="auxiliary"
          display="grid"
          gridTemplateRows="auto 1fr"
          minHeight={`calc(100dvh - ${menuSizes.HEADER_HEIGHT_MOBILE}px - ${menuSizes.BUTTON_ACTION_HEIGHT}px)`}
          overflowY="auto"
        >
          <Box
            color="brand500"
            data-testid={id}
            id={id}
            fontSize={{ _: 24, sm: 28 }}
            fontWeight="600"
            lineHeight={46}
            padding={{ _: '8px 0 0 16px', sm: 'initial' }}
            tag="h2"
          >
            {title}
          </Box>
          {children}
        </Box>
      </Visible>
      <Hidden xs>
        {title ? (
          <>
            <Box
              alignItems={{ sm: 'center' }}
              backgroundColor={{ _: 'auxiliary', sm: 'initial' }}
              display={{ sm: 'grid' }}
              gridTemplateColumns={{
                sm: goBack ? '48px 1fr auto' : '1fr auto',
              }}
              marginTop={!action && { sm: '10px' }}
              marginBottom={action ? { sm: '16px' } : { sm: '24px' }}
              padding={{ _: '0 16px 16px', sm: 'initial' }}
              position="relative"
            >
              {goBack ? (
                <Box placeSelf="center center">
                  <ButtonBack onClick={goBack} aria-label="patata" />
                </Box>
              ) : null}
              <Box
                color="brand500"
                data-testid={id}
                id={id}
                fontSize={{ _: 24, sm: 28 }}
                fontWeight="600"
                lineHeight={46}
                padding={{ _: '8px 0 0 40px', sm: 'initial' }}
                tag="h2"
              >
                {title}
              </Box>
              {action ?? null}
            </Box>
            {children}
          </>
        ) : (
          <>{children}</>
        )}
      </Hidden>
      <Visible xs>{actionVisibleInMobile && action}</Visible>
    </>
  );
};
