import { defineMessages } from 'react-intl';

export const actions = defineMessages({
  add: { id: 'gofre.Documents.List.List.Actions.add' },
  addNew: { id: 'gofre.Documents.List.List.Actions.add-new' },
  addTestInvoice: { id: 'gofre.Documents.List.List.Actions.addTestInvoice' },
  addDraft: { id: 'gofre.Documents.List.List.Actions.add-draft' },
  addInvoice: { id: 'gofre.Documents.List.List.Actions.add-invoice' },
  emitReceipt: { id: 'gofre.Documents.List.List.Actions.emitReceipt' },
  replaceToInvoice: {
    id: 'gofre.Documents.List.List.Actions.replaceToInvoice',
  },
  addInvoiceDesktop: {
    id: 'gofre.Documents.List.List.Actions.add-invoice-desktop',
  },
  addReceipt: { id: 'gofre.Documents.List.List.Actions.add-receipt' },
  addReceiptCreate: {
    id: 'gofre.Documents.List.List.Actions.add-receipt-create',
  },
  addRecurrent: { id: 'gofre.Documents.List.List.Actions.add-recurrent' },
  copy: { id: 'gofre.Documents.List.List.Actions.copy' },
  replace: { id: 'gofre.Documents.List.List.Actions.replace' },
  delete: { id: 'gofre.Documents.List.List.Actions.delete' },
  download: { id: 'gofre.Documents.List.List.Actions.download' },
  downloadTablet: { id: 'gofre.Documents.List.List.Actions.downloadTablet' },
  abbrDownload: { id: 'gofre.Documents.List.List.Actions.abbrDownload' },
  downloadExcel: { id: 'gofre.Documents.List.List.Actions.download-excel' },
  downloadZip: { id: 'gofre.Documents.List.List.Actions.download-zip' },
  downloadElectronic: {
    id: 'gofre.Documents.List.List.Actions.downloadElectronic',
  },
  fix: { id: 'gofre.Documents.List.List.Actions.fix' },
  openPDF: { id: 'gofre.Documents.List.List.Actions.openPDF' },
  rectificationDocument: {
    id: 'gofre.Documents.List.List.Actions.rectification',
  },
  edit: { id: 'gofre.Documents.List.List.Actions.edit' },
  sortout: { id: 'gofre.Documents.List.List.Actions.sortout' },
  rectificate: { id: 'gofre.Documents.List.List.Actions.rectificate' },
  rectificateDocument: {
    id: 'gofre.Documents.Detail.List.Actions.rectificate',
  },
  issue: { id: 'gofre.Quotes.Detail.actions-issue' },
  issueDeliveryNote: { id: 'gofre.Quotes.Detail.actions-issue-delivery-note' },
  issueCorrective: { id: 'gofre.Quotes.Detail.actions-issue.corrective' },
  createPayment: { id: 'gofre.Documents.List.List.Actions.createPayment' },
  createRemittance: {
    id: 'gofre.Documents.List.List.Actions.createRemittance',
  },
  markAsPaidExpenses: {
    id: 'gofre.Documents.List.List.Actions.Expenses.markAsPaid',
  },
  print: { id: 'gofre.Documents.List.List.Actions.print' },
  selectAll: { id: 'gofre.Documents.List.List.Actions.selectAll' },
  selected: { id: 'gofre.Documents.List.List.Actions.selected' },
  send: { id: 'gofre.Documents.List.List.Actions.send' },
  abbrDownloadExcel: {
    id: 'gofre.Documents.List.List.Actions.abbr-download-excel',
  },
  abbrRectification: {
    id: 'gofre.Documents.List.List.Actions.abbr-rectification',
  },
  abbrDownloadZip: {
    id: 'gofre.Documents.List.List.Actions.abbr-download-zip',
  },
  abbrCreatePayment: {
    id: 'gofre.Documents.List.List.Actions.abbrCreatePayment',
  },
  abbrCreateRemittance: {
    id: 'gofre.Documents.List.List.Actions.abbrCreateRemittance',
  },
  void: { id: 'gofre.Documents.List.List.Actions.void' },
  recurrentDocument: {
    id: 'gofre.Documents.List.List.Actions.recurrent-document',
  },
  recurrentDocumentCreate: {
    id: 'gofre.Documents.List.List.Actions.recurrent-document-create',
  },
  fetchingContactError: {
    id: 'gofre.Document.fetching-contact-error',
  },
  mobileActionsTitle: {
    id: 'gofre.Document.mobile.Actions.title',
  },
});

export const deleteDialog = defineMessages({
  title: { id: 'gofre.Documents.Delete.Modal.title' },
  subtitle: { id: 'gofre.Documents.Delete.Modal.subtitle' },
  text: { id: 'gofre.Documents.Delete.Modal.text' },
  cancel: { id: 'gofre.Documents.Delete.Dialog.cancel' },
  confirm: { id: 'gofre.Documents.Delete.Dialog.confirm' },
});

export const dialogListActions = defineMessages({
  title: { id: 'gofre.Documents.Dialog.ListActions.title' },
});

export const tabs = defineMessages({
  draft: { id: 'gofre.Documents.List.Tabs.draft' },
  issued: { id: 'gofre.Documents.List.Tabs.issued' },
  recurrentInvoice: { id: 'gofre.Documents.List.Tabs.recurrentInvoice' },
});

export const filter = defineMessages({
  closeDialog: { id: 'gofre.Documents.List.Filter.close-dialog' },
  cancelDialog: { id: 'gofre.Documents.List.Filter.cancel-dialog' },
  dateFromLegend: { id: 'gofre.Documents.List.Filter.date-from' },
  dateToLegend: { id: 'gofre.Documents.List.Filter.date-to' },
  dateLabel: { id: 'gofre.Documents.List.Filter.date' },
  dueDateLabel: { id: 'gofre.Documents.List.Filter.due-date' },
  dueDateLabelPlaceholder: {
    id: 'gofre.Documents.List.Filter.due-date-placeholder',
  },
  dueDateOptionToday: { id: 'gofre.Documents.List.Filter.due-date-today' },
  dueDateOptionThisWeek: {
    id: 'gofre.Documents.List.Filter.due-date-this-week',
  },
  dueDateOptionThisMonth: {
    id: 'gofre.Documents.List.Filter.due-date-this-month',
  },
  dueDateOptionExpired: { id: 'gofre.Documents.List.Filter.due-date-expired' },
  dueDateOptionNone: { id: 'gofre.Documents.List.Filter.due-date-none' },
  dialogDescription: { id: 'gofre.Documents.List.Filter.dialog-description' },
  documentStatus: { id: 'gofre.Documents.List.Filter.status.label' },
  documentStatusPlaceholder: {
    id: 'gofre.Documents.List.Filter.status.label-placeholder',
  },
  statusPaid: { id: 'gofre.Documents.List.Filter.status.paid' },
  statusPaidExpense: { id: 'gofre.Documents.List.Filter.status.paid-expense' },
  statusPartiallyPaidExpense: {
    id: 'gofre.Documents.List.Filter.status.partially-paid-expense',
  },
  statusNoPaidExpense: {
    id: 'gofre.Documents.List.Filter.status.no-paid-expense',
  },
  statusIssuedExpense: {
    id: 'gofre.Documents.List.Filter.status.issued-expense',
  },
  statusPartiallyPaid: { id: 'gofre.Documents.List.Filter.status.partial' },
  statusNoPaid: { id: 'gofre.Documents.List.Filter.status.no-paid' },
  statusDue: { id: 'gofre.Documents.List.Filter.status.due' },
  statusCorrective: { id: 'gofre.Documents.List.Filter.status.corrective' },
  statusCorrected: { id: 'gofre.Documents.List.Filter.status.corrected' },
  statusVoid: { id: 'gofre.Documents.List.Filter.status.void' },
  statusDraft: { id: 'gofre.Documents.List.Filter.status.draft' },
  statusIssued: { id: 'gofre.Documents.List.Filter.status.issued' },
  statusNone: { id: 'gofre.Documents.List.Filter.status.none' },
  statusReplaced: { id: 'gofre.Documents.List.Filter.status.replaced' },
  digitalDocumentStatus: {
    id: 'gofre.Documents.List.Filter.digitalDocumentStatus.label',
  },
  digitalDocumentStatusPlaceholder: {
    id: 'gofre.Documents.List.Filter.digitalDocumentStatus.label-placeholder',
  },
  digitalDocumentStatusPresented: {
    id: 'gofre.Documents.List.Filter.digitalDocumentStatus.presented',
  },
  digitalDocumentStatusPresentedWithErrors: {
    id: 'gofre.Documents.List.Filter.digitalDocumentStatus.presentedWithErrors',
  },
  digitalDocumentStatusInProgress: {
    id: 'gofre.Documents.List.Filter.digitalDocumentStatus.inProgress',
  },
  digitalDocumentStatusRejected: {
    id: 'gofre.Documents.List.Filter.digitalDocumentStatus.rejected',
  },
  expandable: { id: 'gofre.Documents.List.Filter.expandable' },
  issueDateToBeforeFromDate: {
    id: 'gofre.Documents.List.Filter.issueDateFromBeforeTo',
  },
  minAmountBiggerThanMaxAmount: {
    id: 'gofre.Documents.List.Filter.minAmountBiggerThanMaxAmount',
  },
  minAmountBiggerThanZero: {
    id: 'gofre.Documents.List.Filter.minAmountBiggerThanZero',
  },
  legend: { id: 'gofre.Documents.List.Filter.legend' },
  salesEqTax: { id: 'gofre.Documents.List.Filter.sales-eq-tax' },
  searchTermLabel: { id: 'gofre.Documents.List.Filter.Field.SearchTerm.label' },
  searchTermLabelQuotes: {
    id: 'gofre.Documents.List.Filter.Field.SearchTerm.quotes.label',
  },
  searchTermPlaceholder: {
    id: 'gofre.Documents.List.Filter.Field.SearchTerm.placeholder',
  },
  searchTermMinLength: {
    id: 'gofre.Documents.List.Filter.searchTermMinLength',
  },
  minAmountEUR: {
    id: 'gofre.Documents.List.Filter.minAmount',
  },
  maxAmountEUR: {
    id: 'gofre.Documents.List.Filter.maxAmount',
  },
  minAmountWithoutCurrency: {
    id: 'gofre.Documents.List.Filter.minAmountWithoutCurrency',
  },
  maxAmountWithoutCurrency: {
    id: 'gofre.Documents.List.Filter.maxAmountWithoutCurrency',
  },
  amountPlaceholder: { id: 'gofre.Documents.List.Filter.amountPlaceholder' },
  button: { id: 'gofre.Documents.List.Filter.button' },
  periodicityPlaceholder: {
    id: 'gofre.Documents.Recurrent.Filter.periodicity.label',
  },
  periodicityLabel: {
    id: 'gofre.Documents.Recurrent.Filter.periodicity.placeholder',
  },
  searchTermLabelQuote: {
    id: 'gofre.Documents.Quote.Filter.SearchTerm.label',
  },
  searchTermLabelRecurrent: {
    id: 'gofre.Documents.Recurrent.Filter.SearchTerm.label',
  },
  searchTermPlaceholderRecurrent: {
    id: 'gofre.Documents.Recurrent.Filter.SearchTerm.placeholder',
  },
  dateLabelRecurrent: {
    id: 'gofre.Documents.Recurrent.Filter.date.label',
  },
  documentTagRejected: {
    id: 'gofre.Documents.Recurrent.Filter.documentTag.rejected.gender',
  },
  documentTagAcepted: {
    id: 'gofre.Documents.Recurrent.Filter.documentTag.acepted.gender',
  },
  documentTagPending: {
    id: 'gofre.Documents.Recurrent.Filter.documentTag.pending',
  },
  documentTagNone: {
    id: 'gofre.Documents.Recurrent.Filter.documentTag.none',
  },
  documentTagPlaceholder: {
    id: 'gofre.Documents.Recurrent.Filter.documentTag.placeholder',
  },
  documentTag: {
    id: 'gofre.Documents.Recurrent.Filter.documentTag.label',
  },
  documentTagInoviced: {
    id: 'gofre.Documents.Recurrent.Filter.documentTag.invoiced',
  },
  documentTagNotInoviced: {
    id: 'gofre.Documents.Recurrent.Filter.documentTag.notInvoiced',
  },
  documentTagCanceled: {
    id: 'gofre.Documents.Recurrent.Filter.documentTag.canceled',
  },
  documentTagDelivered: {
    id: 'gofre.Documents.Recurrent.Filter.documentTag.delivered',
  },
});

export const filterDeliveryNote = defineMessages({
  documentTagDelivered: {
    id: 'gofre.Documents.Filter.documentTag.delivered',
  },
  documentTagCanceled: {
    id: 'gofre.Documents.Filter.documentTag.canceled',
  },
  documentTagInoviced: {
    id: 'gofre.Documents.Filter.documentTag.invoiced',
  },
  documentTagNoInoviced: {
    id: 'gofre.Documents.Filter.documentTag.noInvoiced',
  },
  deliveryNoteLabel: {
    id: 'gofre.deliveryNote.documentTag.label',
  },
});

export const filterProforma = defineMessages({
  dueDateOptionToday: {
    id: 'gofre.Proforma.List.Filter.due-date-today',
  },
  dueDateOptionThisWeek: {
    id: 'gofre.Proforma.List.Filter.due-date-this-week',
  },
  dueDateOptionThisMonth: {
    id: 'gofre.Proforma.List.Filter.due-date-this-month',
  },
  dueDateOptionExpired: {
    id: 'gofre.Proforma.List.Filter.due-date-expired',
  },
  dueDateOptionNone: {
    id: 'gofre.Proforma.List.Filter.due-date-none',
  },
  documentTagRejected: {
    id: 'gofre.Proforma.Filter.documentTag.rejected',
  },
  documentTagAcepted: {
    id: 'gofre.Proforma.Filter.documentTag.acepted',
  },
  documentTagPending: {
    id: 'gofre.Proforma.Filter.documentTag.pending',
  },
  documentTagNone: {
    id: 'gofre.Proforma.Filter.documentTag.none',
  },
  legend: {
    id: 'gofre.Proforma.List.Filter.legend',
  },
  searchTermLabelQuotes: {
    id: 'gofre.Proforma.List.Filter.Field.SearchTerm.quotes.label',
  },
  searchTermPlaceholder: {
    id: 'gofre.Proforma.List.Filter.Field.SearchTerm.placeholder',
  },
  documentTag: {
    id: 'gofre.Proforma.Filter.documentTag.label',
  },
  documentTagPlaceholder: {
    id: 'gofre.Proforma.Filter.documentTag.placeholder',
  },
  dateFromLegend: {
    id: 'gofre.Proforma.List.Filter.date-from',
  },
  dateToLegend: {
    id: 'gofre.Proforma.List.Filter.date-to',
  },
  dueDateLabel: { id: 'gofre.Proforma.List.Filter.due-date' },
  dueDateLabelPlaceholder: {
    id: 'gofre.Proforma.List.Filter.due-date-placeholder',
  },
});

export const listDocumentHeader = defineMessages({
  category: { id: 'gofre.Documents.List.List.Issued.Header.category' },
  code: { id: 'gofre.Documents.List.List.Issued.Header.code' },
  identifier: { id: 'gofre.Documents.List.List.Issued.Header.identifier' },
  codeProforma: { id: 'gofre.Documents.List.List.Issued.Header.codeProforma' },
  codeQuotes: { id: 'gofre.Documents.List.List.Issued.Header.codeQuotes' },
  customer: { id: 'gofre.Documents.List.List.Issued.Header.customer' },
  deliveryNote: { id: 'gofre.Documents.List.List.Issued.Header.deliveryNote' },
  dueDate: { id: 'gofre.Documents.List.List.Issued.Header.dueDate' },
  issuedDate: { id: 'gofre.Documents.List.List.Issued.Header.issuedDate' },
  provider: { id: 'gofre.Documents.List.List.Issued.Header.provider' },
  receipt: { id: 'gofre.Documents.List.List.Issued.Header.receipt' },
  status: { id: 'gofre.Documents.List.List.Issued.Header.status' },
  tbai: { id: 'gofre.Documents.List.List.Issued.Header.tbai' },
  total: { id: 'gofre.Documents.List.List.Issued.Header.total' },
});

export const listQuoteHeader = defineMessages({
  issuedDate: { id: 'gofre.Quote.List.List.Issued.Header.issuedDate' },
});

export const listRecurrentHeader = defineMessages({
  client: { id: 'gofre.Documents.List.List.Recurrent.Header.client' },
  expensesClient: {
    id: 'gofre.Documents.List.List.Recurrent.Expenses.Header.client',
  },
  initDate: { id: 'gofre.Documents.List.List.Recurrent.Header.initDate' },
  period: { id: 'gofre.Documents.List.List.Recurrent.Header.period' },
  nextDate: { id: 'gofre.Documents.List.List.Recurrent.Header.nextDate' },
  total: { id: 'gofre.Documents.List.List.Recurrent.Header.total' },
  identifier: { id: 'gofre.Documents.List.List.Issued.Header.identifier' },
  category: { id: 'gofre.Documents.List.List.Recurrent.Header.category' },
  select: { id: 'gofre.Documents.List.List.Recurrent.Header.select' },
});

export const title = defineMessages({
  issued: { id: 'gofre.Documents.List.Title.issued' },
  recurrent: { id: 'gofre.Documents.List.Title.recurrent' },
  draft: { id: 'gofre.Documents.List.Title.draft' },
  expenses: { id: 'gofre.Documents.List.Title.expenses' },
  recurrentExpenses: { id: 'gofre.Documents.List.Title.recurrentExpenses' },
  test: { id: 'gofre.Documents.List.Title.test' },
});

export const tags = defineMessages({
  delete: { id: 'gofre.Documents.List.Tag.delete' },
  draft: { id: 'gofre.Documents.List.Tag.draft' },
  due: { id: 'gofre.Documents.List.Tag.due' },
  paid: { id: 'gofre.Documents.List.Tag.paid' },
  paidExpense: { id: 'gofre.Documents.List.Tag.paid-expense' },
  partiallyPaid: { id: 'gofre.Documents.List.Tag.partially-paid' },
  partiallyPaidExpense: {
    id: 'gofre.Documents.List.Tag.partially-paid-expense',
  },
  noPaid: { id: 'gofre.Documents.List.Tag.no-paid' },
  noPaidExpense: { id: 'gofre.Documents.List.Tag.no-paid-expense' },
  pending: { id: 'gofre.Documents.List.Tag.pending' },
  accepted: { id: 'gofre.Documents.List.Tag.accepted' },
  rejected: { id: 'gofre.Documents.List.Tag.rejected' },
  replaced: { id: 'gofre.Documents.List.Tag.replaced' },
  dueToday: { id: 'gofre.Documents.List.Tag.due-today' },
  dueWeek: { id: 'gofre.Documents.List.Tag.due-week' },
  dueMonth: { id: 'gofre.Documents.List.Tag.due-month' },
  dueExpired: { id: 'gofre.Documents.List.Tag.due-expired' },
  dueExpiredQuotes: { id: 'gofre.Documents.List.Tag.due-expired-quotes' },
  issued: { id: 'gofre.Documents.List.Tag.issued' },
  issuedExpense: { id: 'gofre.Documents.List.Tag.issued-expense' },
  issuedDateFrom: { id: 'gofre.Documents.List.Tag.issued-from' },
  issuedDateTo: { id: 'gofre.Documents.List.Tag.issued-to' },
  nextInvoiceFrom: { id: 'gofre.Documents.List.Tag.next-invoice-from' },
  nextInvoiceTo: { id: 'gofre.Documents.List.Tag.next-invoice-to' },
  void: { id: 'gofre.Documents.List.Tag.void' },
  corrective: { id: 'gofre.Documents.List.Tag.corrective' },
  corrected: { id: 'gofre.Documents.List.Tag.corrected' },
  digitalDocumentStatusPRESENTED: {
    id: 'gofre.Documents.List.Tag.digital-document-status-PRESENTED',
  },
  digitalDocumentStatusPRESENTED_WITH_ERRORS: {
    id: 'gofre.Documents.List.Tag.digital-document-status-PRESENTED_WITH_ERRORS',
  },
  digitalDocumentStatusIN_PROGRESS: {
    id: 'gofre.Documents.List.Tag.digital-document-status-IN_PROGRESS',
  },
  digitalDocumentStatusREJECTED: {
    id: 'gofre.Documents.List.Tag.digital-document-status-REJECTED',
  },
  digitalDocumentStatusFAIL_TO_CONTACT: {
    id: 'gofre.Documents.List.Tag.digital-document-status-FAIL_TO_CONTACT',
  },
  minAmount: {
    id: 'gofre.Documents.List.Tag.minAmount',
  },
  maxAmount: {
    id: 'gofre.Documents.List.Tag.maxAmount',
  },
  invoiced: { id: 'gofre.Documents.List.Tag.invoiced' },
  noiInvoiced: { id: 'gofre.Documents.List.Tag.noInvoiced' },
  canceled: { id: 'gofre.Documents.List.Tag.canceled' },
  delivered: { id: 'gofre.Documents.List.Tag.delivered' },
});

export const zeroState = defineMessages({
  link: { id: 'gofre.Documents.List.ZeroState.link' },
  subtitle: { id: 'gofre.Documents.List.ZeroState.subtitle' },
  title: { id: 'gofre.Documents.List.ZeroState.title' },
});

export const zeroStateRecurrent = defineMessages({
  link: { id: 'gofre.Documents.List.ZeroStateRecurrent.link' },
  subtitle: { id: 'gofre.Documents.List.ZeroStateRecurrent.subtitle' },
  title: { id: 'gofre.Documents.List.ZeroStateRecurrent.title' },
});

export const zeroStateDraft = defineMessages({
  link: { id: 'gofre.Documents.List.ZeroStateDraft.link' },
  subtitle: { id: 'gofre.Documents.List.ZeroStateDraft.subtitle' },
  title: { id: 'gofre.Documents.List.ZeroStateDraft.title' },
});

export const zeroStateExpenses = defineMessages({
  link: { id: 'gofre.Documents.List.expenses.ZeroState.link' },
  subtitle: { id: 'gofre.Documents.List.expenses.ZeroState.subtitle' },
  title: { id: 'gofre.Documents.List.expenses.ZeroState.title' },
});

export const zeroStateQuotes = defineMessages({
  link: { id: 'gofre.Quotes.List.ZeroState.link' },
  linkMobile: { id: 'gofre.Quotes.List.ZeroState.linkMobile' },
  subtitle: { id: 'gofre.Quotes.List.ZeroState.subtitle' },
  title: { id: 'gofre.Quotes.List.ZeroState.title' },
});

export const zeroStateProforma = defineMessages({
  link: { id: 'gofre.Proforma.List.ZeroState.link' },
  linkMobile: { id: 'gofre.Proforma.List.ZeroState.linkMobile' },
  subtitle: { id: 'gofre.Proforma.List.ZeroState.subtitle' },
  title: { id: 'gofre.Proforma.List.ZeroState.title' },
});

export const zeroStateDeliveryNote = defineMessages({
  link: { id: 'gofre.deliveryNote.List.ZeroState.link' },
  linkMobile: { id: 'gofre.deliveryNote.List.ZeroState.linkMobile' },
  subtitle: { id: 'gofre.deliveryNote.List.ZeroState.subtitle' },
  title: { id: 'gofre.deliveryNote.List.ZeroState.title' },
});

export const zeroResults = defineMessages({
  title: { id: 'gofre.Documents.List.ZeroResults.title' },
  subtitle: { id: 'gofre.Documents.List.ZeroResults.subtitle' },
});

export const zeroResultsQuotes = defineMessages({
  title: { id: 'gofre.Quotes.List.ZeroResults.title' },
  subtitle: { id: 'gofre.Quotes.List.ZeroResults.subtitle' },
});

export const zeroResultsDeliveryNotes = defineMessages({
  title: { id: 'gofre.DeliveryNotes.List.ZeroResults.title' },
  subtitle: { id: 'gofre.DeliveryNotes.List.ZeroResults.subtitle' },
});

export const zeroResultsProforma = defineMessages({
  title: { id: 'gofre.Proforma.List.ZeroResults.title' },
  subtitle: { id: 'gofre.Proforma.List.ZeroResults.subtitle' },
});

export const deleteModal = defineMessages({
  success: { id: 'gofre.Documents.Delete.Modal.success' },
  error: { id: 'gofre.Documents.Delete.Modal.error' },
});

export const notificationsMessages = defineMessages({
  downloadZipError: {
    id: 'gofre.Documents.List.List.Actions.download-zip.error',
  },
  error: { id: 'gofre.Documents.List.error' },
  downloadExcelError: {
    id: 'gofre.Documents.List.List.Actions.download-excel.error',
  },
  noContact: { id: 'gofre.Document.no-contact' },
});

export const quoteNotificationsMessages = defineMessages({
  error: { id: 'gofre.Quotes.List.error' },
  errorDownload: { id: 'gofre.Quotes.List.errorDownload' },
});

export const filterInput = defineMessages({
  button: { id: 'gofre.Documents.List.filterInput.button' },
  label: { id: 'gofre.Documents.List.filterInput.label' },
  labelMobile: { id: 'gofre.Documents.List.filterInput.labelMobile' },
  placeholderDraft: {
    id: 'gofre.Documents.List.filterInput.placeholder.draft',
  },
  placeholderExpenses: {
    id: 'gofre.Documents.List.filterInput.placeholder.expenses',
  },
  placeholderMobile: {
    id: 'gofre.Documents.List.filterInput.placeholder.documents.mobile',
  },
  placeholderRecurrentExpenses: {
    id: 'gofre.Documents.List.filterInput.placeholder.recurrentExpenses',
  },
  placeholderRecurrentExpensesMobile: {
    id: 'gofre.Documents.List.filterInput.placeholder.recurrentExpensesMobile',
  },
});

export const actionIsReacepitModal = defineMessages({
  title: { id: 'gofre.actionIsReacepit.modal.title' },
  text01: { id: 'gofre.actionIsReacepit.modal.text01' },
  text02: { id: 'gofre.actionIsReacepit.modal.text02' },
  buttonCancel: { id: 'gofre.actionIsReacepit.modal.button.cancel' },
  buttonAction: { id: 'gofre.actionIsReacepit.modal.button.action' },
});

export const deliveryNoteIssue = defineMessages({
  title: { id: 'gofre.deliveryNote.issue.title' },
  subtitle: { id: 'gofre.deliveryNote.issue.subtitle' },
  text: { id: 'gofre.deliveryNote.issue.text' },
  button: { id: 'gofre.deliveryNote.issue.button' },
  cancel: { id: 'gofre.deliveryNote.issue.cancel' },
  noSameContact: { id: 'gofre.deliveryNote.issue.errors.noSameContact' },
});
