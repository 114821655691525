import { createElement } from 'react';

import { BasicLinkAttrs, LinkOptions, FormatHelper } from './types';

const formatLinkFromMarkdown = (input: string): BasicLinkAttrs => {
  const regex = /\[([^\]]+)\]\(([^)]+)\)/;
  const matches = input.match(regex);
  return matches
    ? { text: matches[1], href: matches[2] }
    : { text: '', href: '' };
};

const link = (options?: LinkOptions) => (chunk: string[]) => {
  const linkAttrs = formatLinkFromMarkdown(chunk[0]);
  const { secure, ...rest } = options || {};
  if (secure) {
    linkAttrs.data = { href: linkAttrs.href };
    linkAttrs.href = '#';
  }
  return createElement('a', {
    children: linkAttrs.text,
    ...linkAttrs,
    ...rest,
  });
};

const download = (options?: LinkOptions) => link({ ...options });

const secureDownload = (options?: LinkOptions) =>
  download({ ...options, secure: true });

export const formatHelpers: FormatHelper = {
  br: () => createElement('br'),
  link: link(),
  download: download(),
  downloadExcel: download({ type: 'application/vnd.ms-excel' }),
  secureDownload,
};
