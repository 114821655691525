import { FC, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import styled from 'styled-components';

import useTranslations from 'modules/I18n/hooks/useTranslations';
import useNotifications from 'modules/Notifications/hooks/useNotifications';
import useTheme from 'modules/Theme/hooks/useTheme';
import accesibleHide from 'modules/Theme/mixins/accessibleHidden';
import Box from 'modules/Ui/Box';
import Button from 'modules/Ui/Form/Button';
import hoverStyles from 'modules/Ui/Link/hoverStyle';
import Text from 'modules/Ui/Text';
import MarkDown from 'modules/Ui/TextMarkdown';

import IconCross from '../Icons/IconCross';
import ContentView from './ContentView';
import messages, { fileMessages } from './messages';

export interface ImageFile extends Blob {
  previewUrl?: string;
}

interface Props {
  onChange: (url?: ImageFile | File | null) => void;
  title: string;
  description: string | JSX.Element;
  content: string;
  previewUrl?: string;
  setPreviewUrl: (value?: string) => void;
  accept?: string;
  isFile?: boolean;
  maxSize?: number;
}

const CloseButton = styled(Button)``;
const Droparea = styled(Box)<Props>`
  label {
    &:focus {
      color: ${(props) => props.theme.colors.brand500};
    }
    @media (hover: hover) {
      transition: color 0.2s ease-in;
      &:hover {
        color: ${(props) => props.theme.colors.brand500};
        ${({ isFile }) => (!isFile ? 'cursor: pointer;' : undefined)}
      }
    }
  }

  &:focus {
    outline: none;
    label {
      color: ${(props) => props.theme.colors.brand500};
    }
  }

  input {
    display: block !important;
    ${accesibleHide}
  }

  button {
    @media (hover: hover) {
      &:hover {
        color: ${(props) => props.theme.colors.brand500};
      }
    }
    &:focus {
      color: ${(props) => props.theme.colors.brand500};
    }
  }

  ${CloseButton} {
    @media (hover: hover) {
      &:hover {
        background-color: ${(props) => props.theme.colors.primary500};
      }
    }

    &:focus {
      background-color: ${(props) => props.theme.colors.primary500};
    }
  }
`;

const StyledText = styled(MarkDown)`
  a {
    font-size: ${(props) => props.theme.fontSizes[14]};
    ${hoverStyles}
    text-decoration: underline;
  }
`;

const Dropzone: FC<Props> = ({
  onChange,
  content,
  title,
  description,
  previewUrl,
  setPreviewUrl,
  accept = 'image/jpeg, image/png',
  isFile = false,
  maxSize = 6291456, // 6mb in bytes
}) => {
  const { t } = useTranslations();
  const notifications = useNotifications();
  const [fileName, setFileName] = useState('');
  const texts = isFile ? fileMessages : messages;
  const theme = useTheme();

  const descriptionElement =
    typeof description === 'string' ? (
      <StyledText
        color="gray600"
        fontSize={14}
        lineHeight={18}
        marginBottom="8px"
        text={description}
      />
    ) : (
      description
    );

  const onChangeFile = (image: Blob) => {
    if (previewUrl) URL.revokeObjectURL(previewUrl);
    const newPreviewUrl = URL.createObjectURL(image);
    setPreviewUrl(newPreviewUrl);
    onChange(Object.assign(image, { previewUrl: newPreviewUrl }));
  };

  const onDrop = useCallback((acceptedFiles) => {
    const [file] = acceptedFiles;
    if (!file) return;
    setFileName(file.name);
    onChangeFile(file);
  }, []);

  const onRemoveImageClick = (ev: React.MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
    ev.preventDefault();
    setPreviewUrl(undefined);
    onChange(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    multiple: false,
    onDrop,
    onDropRejected: (files) => {
      const errorCode = files[0].errors[0].code;
      notifications.error(texts[errorCode].id);
    },
    maxSize,
  });

  const inputProps = getInputProps();
  const rootElementProps = getRootProps();

  useEffect(
    () => () => {
      // Cleanup objectURL on unmount
      if (previewUrl) URL.revokeObjectURL(previewUrl);
    },
    [previewUrl]
  );

  return (
    <section>
      <Text
        color="gray800"
        fontSize={18}
        fontWeight="600"
        lineHeight={18}
        marginBottom={{ _: '4px', sm: '8px' }}
        tag="h3"
        textTransform="uppercase"
      >
        {title}
      </Text>
      {descriptionElement}
      <Droparea
        {...rootElementProps}
        aria-live="polite"
        background={`transparent url(${previewUrl}) no-repeat center center`}
        backgroundSize="contain"
        border={previewUrl && `1px solid ${theme.colors.primary100}`}
        display="flex"
        height="165px"
        justifyContent="center"
        marginBottom="16px"
        overflow="hidden"
        position="relative"
      >
        {previewUrl && (
          <Box position="absolute" right="2px" top="2px">
            <CloseButton
              alignItems="center"
              aria-label={t(messages.deleteFile)}
              backgroundColor={theme.colors.backdropDark}
              borderRadius="50%"
              color={theme.colors.primary500}
              data-testid="logo-remove"
              display="flex"
              height="24px"
              justifyContent="center"
              onClick={onRemoveImageClick}
              type="button"
              width="24px"
            >
              <IconCross color="gray0" size={16} />
            </CloseButton>
          </Box>
        )}
        {!previewUrl && <input id="drop-input" {...inputProps} />}
        <ContentView
          content={content}
          fileName={fileName}
          isFile={isFile}
          previewUrl={previewUrl}
        />
      </Droparea>
    </section>
  );
};

export default Dropzone;
