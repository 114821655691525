import { Contact, emptyContact } from 'modules/Contacts/models';
import { DocumentDefaultValues } from 'modules/DefaultValues/models/document-default-values';
import { createAdditionalExpense } from 'modules/Documents/helpers/default-additionalExpense';
import { createDefaultConcept } from 'modules/Documents/helpers/default-concept';
import {
  DocumentPeriodType,
  DocumentStatus,
  Document,
  RecurrentDocument,
} from 'modules/Documents/models/document';
import { PersonType } from 'modules/Documents/models/personType';
import { CreatedPaymentMethod } from 'modules/PaymentMethods/models/paymentMethods';
import { DocumentSerie, TargetDocument } from 'modules/Settings/models';
import { TemplateSettings } from 'modules/Settings/Theme/types';
import { Currency } from 'types/currency';

import { NewDocumentForm } from '../../models/form';
import copyDocumentValues from './copyDocumentValues';
import correctiveValues from './correctiveValues';
import hasRetentions from './hasRetentions';
import { mapPaymentMethods } from './helpers';
import issueFromQuoteValues from './issueFromQuoteValues';
import recurrentDocumentValues from './recurrentDocumentValues';
import updateDocumentValues from './updateDocumentValues';

interface SeriesProps {
  initialSerieValue: string | null;
  initialCode: number;
  initialSerialCode: string;
  initialSerie: DocumentSerie;
}

const initialValues = ({
  initialSerieValue,
  initialCode,
  initialSerialCode,
  paymentMethods,
  savedDefaultValues,
  isReceipt,
  templates,
  contact,
  shouldFill140,
}: {
  initialSerieValue: string | null;
  initialCode: number;
  initialSerialCode: string;
  paymentMethods?: CreatedPaymentMethod[];
  savedDefaultValues?: DocumentDefaultValues | null;
  isReceipt?: boolean;
  templates?: TemplateSettings[];
  contact?: Contact;
  shouldFill140?: boolean;
}): NewDocumentForm => {
  const retentionPercentage = isReceipt
    ? undefined
    : savedDefaultValues?.retentionPercentage ?? undefined;
  const showComments = savedDefaultValues?.showComments ?? true;
  const templateId = templates?.[0]?.id || undefined;
  const showProductReference = savedDefaultValues?.showProductReference;
  const contactPaymentMethods = contact?.paymentMethods || [];

  return {
    shouldRedirectAfterDraftCreation: false,
    hasAdditionalExpenses: false,
    additionalExpenses: [createAdditionalExpense()],
    code: initialCode,
    currency: Currency.EUR,
    creationDate: new Date(),
    dueDate: undefined,
    initDateTime: new Date(),
    newDocumentPeriod: DocumentPeriodType.MONTHLY,
    dueDocumentPeriod: DocumentPeriodType.NONE,
    concepts: [createDefaultConcept(savedDefaultValues?.taxRegime ?? '')],
    showComments,
    comments: showComments ? savedDefaultValues?.comments : undefined,
    contact: contact || { ...emptyContact },
    hasRetentions: hasRetentions({
      isReceipt,
      retentionPercentage,
    }),
    hasSalesEqualizationTax: Boolean(
      savedDefaultValues?.hasSalesEqualizationTax
    ),
    reference: '',
    retentionPercentage,
    hasPaidAmount: false,
    paidAmount: 0,
    serialCode: initialSerialCode,
    serieId: initialSerieValue,
    status: DocumentStatus.ISSUED,
    isDefaultDraft: false,
    operationDescription: savedDefaultValues?.operationDescription ?? '',
    operationDateTime: '',
    taxRegime: savedDefaultValues?.taxRegime,
    paymentMethods: mapPaymentMethods(
      contactPaymentMethods.length ? contactPaymentMethods : paymentMethods
    ),
    naturalPerson: shouldFill140,
    naturalPersonType: shouldFill140 ? PersonType.NATURAL : PersonType.LEGAL,
    epigraphIAE: savedDefaultValues?.epigraphIAE,
    lastEpigraphsIAE: savedDefaultValues?.lastEpigraphsIAE ?? [],
    chargingCriterionIRPF: savedDefaultValues?.chargingCriterionIRPF,
    templateId: savedDefaultValues?.templateId ?? templateId,
    showProductReference,
    showLinesWithoutTaxes: savedDefaultValues?.showLinesWithoutTaxes ?? false,
  };
};

const getInitialSerie = (
  series: DocumentSerie[],
  document: Document,
  isCorrective: boolean
) => {
  if (isCorrective) {
    return (
      series.find(
        (serie: DocumentSerie) =>
          serie.targetDocument === TargetDocument.CORRECTIVE
      ) || series[0]
    );
  }
  return (
    series.find(
      (serie: DocumentSerie) => serie.serialCode === document?.serie?.serialCode
    ) || series[0]
  );
};

const getCopyValues = ({
  document,
  initialSerieValue,
  initialCode,
  initialSerialCode,
  isDraft,
  isRecurrent,
  paymentMethods,
  savedDefaultValues,
  isReplacing,
  updatedContact,
}: {
  document: Document | RecurrentDocument;
  initialSerieValue: string | null;
  initialCode: number;
  initialSerialCode: string;
  isDraft?: boolean;
  isRecurrent?: boolean;
  paymentMethods?: CreatedPaymentMethod[];
  savedDefaultValues?: DocumentDefaultValues | null;
  isReplacing?: boolean;
  updatedContact?: Contact;
}) => {
  // if copying from recurrent to recurrent
  if (isRecurrent && (document as RecurrentDocument).documentTemplate) {
    return recurrentDocumentValues({
      document: document as RecurrentDocument,
      paymentMethods,
      isCopying: true,
      savedDefaultValues,
      updatedContact,
      initialSerieValue,
      initialCode,
      initialSerialCode,
    });
  }
  // if copying to regular
  return copyDocumentValues(
    document as Document,
    initialSerieValue,
    initialCode,
    initialSerialCode,
    isDraft,
    paymentMethods,
    savedDefaultValues,
    isReplacing
  );
};

interface GetInitialValuesArgs {
  series: DocumentSerie[];
  isRecurrent?: boolean;
  isDraft?: boolean;
  document?: Document | RecurrentDocument;
  isCopying?: boolean;
  isReplacing?: boolean;
  isIssuing?: boolean;
  contact?: Contact;
  isReceipt?: boolean;
  isCorrective?: boolean;
  paymentMethods?: CreatedPaymentMethod[];
  savedDefaultValues?: DocumentDefaultValues | null;
  templates?: TemplateSettings[];
  isPartialCorrective?: boolean;
  updatedContact?: Contact;
  isBai?: boolean;
  shouldFill140?: boolean;
}

const getInitialValuesFromDocument = (
  args: GetInitialValuesArgs & SeriesProps
): NewDocumentForm => {
  const {
    document,
    isRecurrent,
    isDraft,
    isCopying,
    isIssuing,
    contact,
    paymentMethods,
    savedDefaultValues,
    initialSerie,
    initialSerieValue,
    initialCode,
    initialSerialCode,
    isCorrective,
    isReplacing,
    isReceipt,
    series,
    isPartialCorrective,
    updatedContact,
    isBai,
  } = args;
  if (isCorrective && !isPartialCorrective) {
    return correctiveValues({
      document: document as Document,
      series,
      isReceipt,
      savedDefaultValues,
      paymentMethods,
    });
  }
  if (isIssuing) {
    return issueFromQuoteValues({
      document: document as Document,
      initialSerieValue,
      initialCode,
      initialSerialCode,
      contact: contact as Contact,
      paymentMethods,
      savedDefaultValues,
      updatedContact,
    });
  }
  if (isCopying || isReplacing) {
    return getCopyValues({
      document: document as Document,
      initialSerieValue,
      initialCode,
      initialSerialCode,
      isDraft,
      isRecurrent,
      paymentMethods,
      savedDefaultValues,
      isReplacing,
      updatedContact,
    });
  }

  return isRecurrent
    ? recurrentDocumentValues({
        document: document as RecurrentDocument,
        paymentMethods,
        isCopying,
        savedDefaultValues,
        updatedContact,
        initialSerieValue,
        initialCode,
        initialSerialCode,
      })
    : updateDocumentValues({
        document: document as Document,
        initialSerieValue,
        initialCode,
        initialSerialCode,
        initialSerie,
        isDraft,
        isCopying,
        paymentMethods,
        savedDefaultValues,
        isPartialCorrective,
        isReceipt,
        updatedContact,
        isBai,
      });
};

const getInitialValues = (args: GetInitialValuesArgs) => {
  const {
    series,
    document,
    isReceipt,
    paymentMethods,
    savedDefaultValues,
    templates,
    isPartialCorrective,
    isReplacing,
    isCopying,
    contact,
    updatedContact,
    shouldFill140,
  } = args;

  const initialSerie = getInitialSerie(
    series,
    document as Document,
    !!isPartialCorrective
  );

  const initialSerieValue = initialSerie ? initialSerie.id : null;
  const initialCode = initialSerie && initialSerie.lastNumber + 1;
  const initialSerialCode = initialSerie ? initialSerie.serialCode : '';

  if (document) {
    return getInitialValuesFromDocument({
      ...args,
      initialSerie,
      initialSerieValue,
      initialCode,
      initialSerialCode,
      isReplacing,
      isCopying,
      savedDefaultValues,
      updatedContact,
    });
  }
  return initialValues({
    initialSerieValue,
    initialCode,
    initialSerialCode,
    paymentMethods,
    savedDefaultValues,
    isReceipt,
    templates,
    contact,
    shouldFill140,
  });
};

export default getInitialValues;
