export enum UserNotificationTypes {
  bookkeeperNewInvitation = 'bookkeeper--new-invitation',
  bookkeeperInvitationAccepted = 'bookkeeper--invitation-accepted',
  bookkeeperInvoiceSettingsUpdated = 'bookkeeper--invoice-settings-updated',
  bookkeeperDownloadZip = 'bookkeeper--download-zip',
  userBookkeeperNewInvitation = 'user--bookkeeper-new-invitation',
  userBookkeeperAcceptInvitation = 'user--bookkeeper-accept-invitation',
  userAnotherUserUpdateInvoiceSettings = 'user--another-user-update-invoice-settings',
  userAnotherUserCreateNewInvoice = 'user--another-user-create-new-invoice',
  userDownloadZip = 'user--download-zip',
  userAnotherUserUpdateYourRole = 'user--another-user-update-your-role',
  userUserNewInvitation = 'user--user-new-invitation',
  userUserAcceptInvitation = 'user--user-accept-invitation',
  userIssueInvoiceFromRecurrent = 'user--issue-invoice-from-recurrent',
  businessElectronicProviderConnected = 'business--electronic-provider-connected',
  businessElectronicProviderDisconnected = 'business--electronic-provider-disconnected',
  businessTaxDocumentPresented = 'business--tax-document-presented',
  businessTaxDocumentPresentedWithErrors = 'business--tax-document-presented-with-errors',
  businessTaxDocumentRejected = 'business--tax-document-rejected',
  businessTaxDocumentPending = 'business--tax-document-pending',
  documentAutosaved = 'document--autosaved',
  stockWarningFromRecurrent = 'user--stock-warning-from-recurrent',
}

export type DownloadZipPayload = {
  type: UserNotificationTypes.userDownloadZip;
  zipId: string;
  businessId: string;
  downloaded: boolean;
};

export type UserNotificationStockWarningFromRecurrent = {
  type: UserNotificationTypes.stockWarningFromRecurrent;
  documentId: string;
  products: {
    id: string;
    name: string;
    reference?: string;
  }[];
};

export type UserNotificationIssueInvoiceFromRecurrent = {
  type: UserNotificationTypes.userIssueInvoiceFromRecurrent;
  documentSerie: string;
  documentCode: string;
  documentIdentifier: string;
  documentId: string;
  multipleDocuments: boolean;
};

export type UserNotificationPaylodType =
  //
  // Bookkeeper
  //
  | {
      /*
        New invitation to manage a business
       */
      type: UserNotificationTypes.bookkeeperNewInvitation;
      clientFiscalName: string;
      businessId: string;
      redeemToken: string;
      accepted: boolean;
    }
  | {
      /*
       * A user you had invited accepts the invitation
       */
      type: UserNotificationTypes.bookkeeperInvitationAccepted;
      clientFiscalName: string;
      businessId: string;
    }
  | {
      /*
       * A customer changes the billing options
       */
      type: UserNotificationTypes.bookkeeperInvoiceSettingsUpdated;
      clientFiscalName: string;
    }
  //
  // User
  //
  | {
      /*
       * New invitation from a manager
       */
      type: UserNotificationTypes.userBookkeeperNewInvitation;
      bookkeeperFiscalName: string;
      bookkeeperBusinessId: string;
      bookkeeperUserId: string;
      redeemToken: string;
      accepted: boolean;
    }
  | {
      /*
       * A manager whom you had invited accepts the invitation
       */
      type: UserNotificationTypes.userBookkeeperAcceptInvitation;
      bookkeeperFiscalName: string;
      bookkeeperBusinessId: string;
      bookkeeperUserId: string;
    }
  | {
      /*
       * Manager or other business user modifies invoice options
       */
      type: UserNotificationTypes.userAnotherUserUpdateInvoiceSettings;
      bookkeeperFiscalName: string;
      userName: string;
    }
  | {
      /*
       * Another user of your business has created a new invoice
       */
      type: UserNotificationTypes.userAnotherUserCreateNewInvoice;
      userName: string;
      businessFiscalName: string;
      documentId?: string;
      multipleDocuments: boolean;
    }
  | {
      /*
       * Another user of your business has modified your user permissions
       */
      type: UserNotificationTypes.userAnotherUserUpdateYourRole;
      userName: string;
      businessFiscalName: string;
      businessId: string;
    }
  | {
      /*
       * A user has invited you to become a user of his business
       */
      type: UserNotificationTypes.userUserNewInvitation;
      clientFiscalName: string;
      businessId: string;
      redeemToken: string;
      accepted: boolean;
    }
  | {
      /*
       * A user you had invited accepts the invitation
       */
      type: UserNotificationTypes.userUserAcceptInvitation;
      clientFiscalName: string;
      businessId: string;
    }
  /*
   * A recurring invoice comes out
   */
  | UserNotificationIssueInvoiceFromRecurrent

  //
  // BUSINESS
  //
  /**
   * Conexión con la hacienda vasca
   * "Nombre fiscal negocio" se ha conectado correctamente a la Sede Electrónica de Hacienda.
   */
  | {
      type: UserNotificationTypes.businessElectronicProviderConnected;
      businessFiscalName: string;
      businessId: string;
      isBai?: boolean;
    }
  /*
   * Desconexión de la hacienda vasca
   * "Nombre fiscal negocio" se ha desconectado de la Sede Electrónica de Hacienda.
   */
  | {
      type: UserNotificationTypes.businessElectronicProviderDisconnected;
      businessFiscalName: string;
      businessId: string;
      isBai?: boolean;
    }
  /*
   * Envío con éxito de factura a hacienda
   * La factura "Num de factura" de “nombre fiscal negocio” ha sido presentada con éxito en la Sede Electrónica de Hacienda.
   * Link al detalle de factura
   */
  | {
      type: UserNotificationTypes.businessTaxDocumentPresented;
      businessFiscalName: string;
      documentIdentificer: string;
      isBai?: boolean;
    }
  /*
   * Factura presentada con errores normal a hacienda
   * La factura "Num de factura" de “nombre fiscal negocio” ha sido presentada con errores en la Sede Electrónica de Hacienda.
   * Link al detalle de factura
   */
  | {
      type: UserNotificationTypes.businessTaxDocumentPresentedWithErrors;
      businessFiscalName: string;
      documentIdentifier: string;
      isBai?: boolean;
    }
  /*
   * Factura rechazada
   * La factura "Num de factura" de “nombre fiscal negocio” ha sido rechazada por la Sede Electrónica de Hacienda.
   * Link al detalle de factura
   */
  | {
      type: UserNotificationTypes.businessTaxDocumentRejected;
      businessFiscalName: string;
      documentIdentifier: string;
      isBai?: boolean;
    }
  /*
   * Factura pendiente de envío a hacienda
   * La factura "Num de factura" de “nombre fiscal negocio” está pendiente de su envío a la Sede Electrónica de Hacienda. Se enviará cuando se restablezca la conexión
   * Link al detalle de factura
   */
  | {
      type: UserNotificationTypes.businessTaxDocumentPending;
      businessFiscalName: string;
      documentIdentifier: string;
      isBai?: boolean;
    }
  | DownloadZipPayload
  //
  // AUTOSAVE
  //
  | {
      type: UserNotificationTypes.documentAutosaved;
    }
  | UserNotificationStockWarningFromRecurrent;

export const isStockWarningFromRecurrent = (
  payload: UserNotificationPaylodType
): payload is UserNotificationStockWarningFromRecurrent => {
  return payload.type === UserNotificationTypes.stockWarningFromRecurrent;
};

export const isIssueDocumentFromRecurrent = (
  payload: UserNotificationPaylodType
): payload is UserNotificationIssueInvoiceFromRecurrent => {
  return payload.type === UserNotificationTypes.userIssueInvoiceFromRecurrent;
};
